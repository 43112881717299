import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import LoggedUserManager from '@/services/LoggedUserManager';
let AdminCompanyInfoComponent = class AdminCompanyInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.companyName = '';
        this.companyIco = '';
        this.companyNote = '';
        this.isEditing = false;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this);
        this.selectedUserName = '';
        this.isUsersLoaded = false;
        this.selectedAdminUser = null;
    }
    get filteredUsers() {
        return this.allUsers.filter((user) => user.username &&
            user.username
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .includes(this.selectedUserName
                .trim()
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')));
    }
    created() {
        this.companyName = this.company.companyName;
        this.companyIco = this.company.ico;
        this.companyNote = this.company.note;
    }
    async updateCompany() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let data = {
                    CompanyName: this.companyName,
                    Ico: this.companyIco,
                    Note: this.companyNote,
                    ResponsiblePersonId: this.selectedAdminUser?.apiUserId
                };
                this.$emit('updateCompany', data, this.company.companyId);
                this.company.companyName = this.companyName;
                this.company.ico = this.companyIco;
                this.company.note = this.companyNote;
                this.company.responsiblePerson = this.selectedAdminUser;
            }
        });
    }
    userNameChanged(user) {
        this.selectedAdminUser = user;
    }
    loginIntoCompany() {
        this.$emit('loginIntoCompany', this.$route.name, this.$route.params);
    }
    editCompany() {
        this.isEditing = !this.isEditing;
        if (!this.isUsersLoaded)
            this.$emit('loadAllUsers');
    }
};
__decorate([
    Prop({ type: Object })
], AdminCompanyInfoComponent.prototype, "company", void 0);
__decorate([
    Prop({ type: Object })
], AdminCompanyInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Array })
], AdminCompanyInfoComponent.prototype, "allUsers", void 0);
AdminCompanyInfoComponent = __decorate([
    Component({})
], AdminCompanyInfoComponent);
export default AdminCompanyInfoComponent;
